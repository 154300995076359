import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import HeaderRibbon from "../../components/Common/HeaderRibbon/HeaderRibbon";
import { Footer } from "../../components/Common/Footer/Footer";
import "./MainLayout.scss";
import { Outlet, useLocation } from "react-router-dom";
import HeaderComponent from "components/Common/Header/Header";
import Sider from "antd/es/layout/Sider";
import SidebarMenu from "./SidebarNavigation";
import { ROUTES_PATH } from "routes/Routes.Constant";

const MainLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const location = useLocation();
  const currentURL = location.pathname;

  useEffect(() => {
    if (
      currentURL.includes(ROUTES_PATH.ADD_PRODUCTS) ||
      currentURL.includes(ROUTES_PATH.EDIT_ORDER) ||
      currentURL.includes(ROUTES_PATH.CREATE_ORDER) ||
      currentURL.includes(ROUTES_PATH.EDIT_PRODUCT)
    ) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentURL]);
  return (
    <>
      <HeaderRibbon ribbonHeight={10} />
      <HeaderComponent collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout className="sider-layout-style">
        <Sider trigger={null} collapsible collapsed={collapsed} className="sider-style">
          <SidebarMenu />
        </Sider>
        <Layout className="site-layout">
          <Content className="content-container">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
      <Footer />
    </>
  );
};

export default MainLayout;
