export const themeSetting = {
  token: {
    colorPrimary: "#002c8c",
    colorBgContainer: "#ffffff",
    fontSize: 14,
    colorText: "#595959",
    colorIcon: "#002c8c",
  },
  components: {
    Radio: {
      colorPrimary: "#002c8c",
    },

    Checkbox: {
      colorPrimary: "#002c8c",
    },
  },
};
        
