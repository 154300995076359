export const ROUTES_PATH = {
  DASHBOARD: "dashboard",
  ORDERS: "orders",
  CREATE_ORDER: "create-order",
  EDIT_ORDER: "edit-order",
  ADD_PRODUCTS: "add-products",
  EDIT_PRODUCT: "edit-product",
  LOGIN: "login",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/user/resetpassword",
  USER_PROFILE: "user/user-profile",
  CHANGE_PASSWORD: "/user/change-password",
  REDIRECT_TO_LOGIN: "/auth/login",
  NOT_AUTHORISED: "/not-authorised",
};
