import { REMEMBER_COOKIE } from "config/constant";
import Cookies from "js-cookie";
import React from "react";
import { Navigate } from "react-router-dom";
interface Props {
  children: React.ReactNode;
}

const RequireAuth: React.FC<Props> = ({ children }) => {
  const isRememberAvailable = Cookies.get(REMEMBER_COOKIE);
  const isSession = localStorage.getItem("sessionActive");
  if (isRememberAvailable === "available" || isSession) {
    return <>{children}</>;
  } else {
    return <Navigate to="/auth/login" replace />;
  }
};

export default RequireAuth;
