import { ISecurityPermission } from "interfaces/ClientInterface/IAbility";
import { mapPermissionsToAbility } from "./utils";
import { PureAbility } from "@casl/ability";

const updateAbility = (
  ability: PureAbility<[string, string]>,
  permissionList: ISecurityPermission[]
) => {
  ability.update(mapPermissionsToAbility(permissionList));
};

export default updateAbility;
