import { OrderStatusListProps } from "interfaces/ClientInterface/IEditOrder";

const getColor = (
  orderStatusList: OrderStatusListProps[],
  value: string,
  fieldName: string,
  selectedValueKey: string
) => {
  const data: OrderStatusListProps | Record<string, never> =
    orderStatusList?.find((item: OrderStatusListProps) => {
      return item[selectedValueKey as keyof OrderStatusListProps] == value;
    }) || {};
  const res = data && (data as OrderStatusListProps)[fieldName as keyof OrderStatusListProps];
  return res;
};

export const getStatusDropDownStyle = (
  orderStatusList: OrderStatusListProps[],
  value: string,
  selectedValueKey: string
) => {
  const bgColor =
    getColor(orderStatusList, value, "CPBackColor", selectedValueKey)?.toString() || "#318311";
  const color =
    getColor(orderStatusList, value, "CPTextColor", selectedValueKey)?.toString() || "#B7EB8F";
  const darkerBorder = adjustColor(bgColor, -50);
  const style = {
    backgroundColor: bgColor,
    border: `1px solid ${darkerBorder}`,
    color: color,
  };
  return style;
};

export const adjustColor = (color: string, amount: number) => {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        ("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
      )
  );
};
