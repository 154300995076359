import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { items } from "components/Dashboard/Dashboard.Constant";
import { useLocation } from "react-router-dom";

export default function SidebarMenu() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(" ");
  useEffect(() => {
    if (location) {
      if (activeLink !== location.pathname) {
        const activeKey = location.pathname.replace(/\//, "");
        setActiveLink(activeKey || "");
      }
    }
  }, [location, activeLink]);
  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={["dashboard"]}
      items={items}
      selectedKeys={[activeLink]}
      className="dashboard-sidebar-menu"
    />
  );
}
