import React, { useContext, useEffect, useState } from "react";
import { AppRouter } from "routes/AppRouter";
import { readUserId } from "api/services/localStorage.service";
import { getPermissionsData } from "api/services/permission.api";
import { AbilityContext } from "ability/can";
import Loader from "components/Common/Loader/Loader";
import "./App.css";
import { ISecurityPermission } from "interfaces/ClientInterface/IAbility";
import { isObjectEmpty } from "utils";
import { PERMISSION_CODE, PERMISSION_SUBJECT } from "ability/utils";

function App() {
  const [loader, setLoader] = useState(false);
  const ability = useContext(AbilityContext);

  /**
   * fetching permissions data and update the ability state.
   */
  useEffect(() => {
    if (readUserId()) {
      setLoader(true);
      readUserId() &&
        getPermissionsData(readUserId(), ability)
          .then((res) => {
            setLoader(false);
            //logout if the login permission is false
            const loginPermission = res?.RoleAndPermissionList?.find(
              (item: ISecurityPermission) => {
                return (
                  item?.Object == PERMISSION_SUBJECT.SECURITY &&
                  item?.PermissionCode == PERMISSION_CODE.ACCESS_ORDER_MANAGEMENT
                );
              }
            );
            if (isObjectEmpty(loginPermission)) {
              window.location.href = "/logout";
            }
          })
          .catch(() => {
            setLoader(false);
          });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readUserId()]);

  if (loader) {
    return <Loader loading={loader} />;
  }

  // eslint-disable-next-line react/react-in-jsx-scope
  return <AppRouter />;
}

export default App;
