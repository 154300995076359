/* eslint-disable max-lines */
/* eslint-disable max-len */
import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { withLoading } from "hocs/withLoading.hoc";
import RequireAuth from "./RequireAuth";
import MainLayout from "layouts/MainLayout/MainLayout";
import { ROUTES_PATH } from "./Routes.Constant";
import { LazyLoader } from "components/Common/LazyLoader/LazyLoader";
import NotAuthorizedPage from "pages/NotAuthorisedPage";

//Pages lazily loaded
const LoginPage = React.lazy(() => import("pages/LoginPage"));
const ResetPassword = React.lazy(() => import("components/ResetPassword/ResetPassword"));
const ForgotPassword = React.lazy(() => import("components/ForgotPassword/ForgotPassword"));
const ManageOrders = React.lazy(() => import("components/Orders/ManageOrder"));
const CreateOrder = React.lazy(() => import("components/CreateOrder/CreateOrder"));
const EditOrderPage = React.lazy(() => import("pages/EditOrderPage"));
const AddProducts = React.lazy(() => import("components/AddProducts/AddProducts"));
const EditProduct = React.lazy(() => import("components/EditProduct/EditProduct"));
const UserProfile = React.lazy(() => import("components/UserProfile/UserProfile"));
const ChangePassword = React.lazy(() => import("components/UserProfile/ChangePassword"));
const AuthLayoutFallback = React.lazy(() => import("layouts/AuthLayout/AuthLayout"));
const DashboardPage = React.lazy(() => import("pages/DashboardPage"));
const Logout = React.lazy(() => import("./Logout"));

const Dashboard = withLoading(DashboardPage);
const LogoutFallback = withLoading(Logout);
export const DASHBOARD_PATH = "/";

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  const suspenseWithLoader = (children: React.ReactNode, delay?: number) => {
    return (
      <Suspense fallback={<LazyLoader {...(delay ? { delay: delay } : {})} />}>{children}</Suspense>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path={`${ROUTES_PATH.DASHBOARD}`} element={<Dashboard />} />
          <Route
            path={`${ROUTES_PATH.ORDERS}`}
            element={suspenseWithLoader(<ManageOrders />, 800)}
          />
          <Route
            path={`${ROUTES_PATH.CREATE_ORDER}`}
            element={suspenseWithLoader(<CreateOrder />)}
          />
          <Route
            path={`${ROUTES_PATH.EDIT_ORDER}/:orderId`}
            element={suspenseWithLoader(<EditOrderPage />)}
          />
          <Route
            path={`${ROUTES_PATH.ADD_PRODUCTS}/:orderId`}
            element={suspenseWithLoader(<AddProducts />)}
          />
          <Route
            path={`${ROUTES_PATH.EDIT_PRODUCT}/:orderProductId/:childSKU`}
            element={suspenseWithLoader(<EditProduct />)}
          />
          <Route
            path={`${ROUTES_PATH.USER_PROFILE}`}
            element={suspenseWithLoader(<UserProfile />)}
          />
          <Route
            path={`${ROUTES_PATH.CHANGE_PASSWORD}`}
            element={suspenseWithLoader(<ChangePassword />)}
          />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path={`${ROUTES_PATH.LOGIN}`} element={suspenseWithLoader(<LoginPage />)} />
        </Route>
        <Route path={`${ROUTES_PATH.LOGOUT}`} element={<LogoutFallback />} />
        <Route
          path={`${ROUTES_PATH.FORGOT_PASSWORD}`}
          element={suspenseWithLoader(<ForgotPassword />)}
        />
        <Route
          path={`${ROUTES_PATH.RESET_PASSWORD}`}
          element={suspenseWithLoader(<ResetPassword />)}
        />
        <Route path={`${ROUTES_PATH.NOT_AUTHORISED}`} element={<NotAuthorizedPage />} />
      </Routes>
    </BrowserRouter>
  );
};
