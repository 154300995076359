import loginJSON from "./login.json";
import forgotPasswordJSON from "./forgotPassword.json";
import dashboardJSON from "./dashboard.json";
import manageOrderJSON from "./manageOrder.json";
import createOrderJSON from "./createOrder.json";
import editOrderJSON from "./editOrder.json";
import editProductJSON from "./editProduct.json";
import addProductJSON from "./addProduct.json";
import userProfileJSON from "./userProfile.json";
import addAddressJSON from "./addAddress.json";
import assignedToJSON from "./assignedTo.json";
import quickAddModalJSON from "./quickAddModal.json";
import commonComponentJSON from "./commonComponent.json";
import productsJSON from "./products.json";
import resetPasswordJSON from "./resetPassword.json";

const combinedEnglishJSON = {
  ...loginJSON,
  ...forgotPasswordJSON,
  ...dashboardJSON,
  ...manageOrderJSON,
  ...createOrderJSON,
  ...editOrderJSON,
  ...editProductJSON,
  ...addProductJSON,
  ...userProfileJSON,
  ...addAddressJSON,
  ...assignedToJSON,
  ...quickAddModalJSON,
  ...commonComponentJSON,
  ...productsJSON,
  ...resetPasswordJSON
};

export default combinedEnglishJSON;
