import React from "react";
import { Col, Row, Typography } from "antd";
import "./Footer.scss";
import { getFooterText } from "utils";

export const Footer: React.FC = () => {
  const { Text } = Typography;
  const copyrightText = getFooterText();
  return (
    <Row>
      <Col span={24} className="footer-style">
        <Text>{copyrightText}</Text>
      </Col>
    </Row>
  );
};