import React, { FC, useEffect, useState } from "react";
import Loader from "../Loader/Loader";

export interface LazyLoaderProps {
  delay?: number;
}

export const LazyLoader: FC<LazyLoaderProps> = ({ delay = 250 }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? <Loader loading={true} /> : null;
};
