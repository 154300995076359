/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { Layout, Row, Col, Menu, Button, Space, Avatar, theme, Tooltip } from "antd";
import type { MenuProps } from "antd";
import { Header } from "antd/es/layout/layout";
import {
  MenuOutlined,
  // BellOutlined,
  // MailOutlined,
  // CiCircleFilled,
  DownOutlined,
} from "@ant-design/icons";
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import { themeSetting } from "config/defaultSettings";
import {  clearLocalStorage, deleteToken, deleteUser, getUserDetails, removeAllCookies} from "api/services/localStorage.service";
import { ROUTES_PATH } from "routes/Routes.Constant";
import { translate } from "i18n";
import { useNavigate } from "react-router-dom";
import { removeLocalStorageData } from "utils/localStorage";
import { STORELIST, TABLECOLUMN, FILTERVALUE, ORDERTYPE, SAVEDVIEWFILTER, RefreshTokenSession} from "config/constant";
const { useToken } = theme;


export interface siderProps {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}

const HeaderComponent = (props: siderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activityTimeoutRef = useRef<any>(null);
  const { token } = useToken();
  const { collapsed, setCollapsed } = props;
  const userData = getUserDetails() ? JSON.parse(getUserDetails() || "") : {};
  const initials = userData?.FirstName?.[0]?.toUpperCase() + userData?.LastName?.[0]?.toUpperCase();
  const navigate = useNavigate();
  
  const handleLogout = () => {
    deleteToken();
    deleteUser();
    removeAllCookies();
    clearLocalStorage();
    const win: Window = window;
    win.location = "/auth/login";
  };

  useEffect(() => {
    activityTimeoutRef.current = setTimeout(() => {
      handleLogout();
    }, RefreshTokenSession * 60 * 1000);

    const activityEvents = ["mousedown", "mousemove", "keypress", "scroll"];
    const handleActivity = () => {
      clearTimeout(activityTimeoutRef.current); // Reset the timeout on activity

      activityTimeoutRef.current = setTimeout(() => {
        handleLogout();
      },RefreshTokenSession * 60 * 1000);
    };

    activityEvents.forEach((event) => {
      document.addEventListener(event, handleActivity);
    });

    handleActivity();

  }, []);

  const items: MenuProps["items"] = [
    // {
    //   key: "notification",
    //   icon: (
    //     <BellOutlined
    //       className="header-menu-items"
    //       style={{ color: themeSetting.token.colorIcon }}
    //     />
    //   ),
    // },
    // {
    //   key: "mail",
    //   icon: (
    //     <MailOutlined
    //       className="header-menu-items"
    //       style={{ color: themeSetting.token.colorIcon }}
    //     />
    //   ),
    // },
    {
      key: "user-profile",
      label: (
        <Space>
          <Tooltip title={`${userData?.FirstName} ${userData?.LastName}`} placement="bottomLeft">
            <Avatar
              style={{
                backgroundColor: token.colorPrimary,
                verticalAlign: "middle",
                color: token.colorBgContainer,
              }}
              size="small"
              gap={2}
            >
              {initials}
            </Avatar>
          </Tooltip>
          <DownOutlined style={{ color: themeSetting.token.colorIcon }} />
        </Space>
      ),
      children: [
        {
          label: (
            <Link to={`${ROUTES_PATH.USER_PROFILE}`} title={translate("common.titleMyProfile")}>
              {translate("common.titleProfile")}
            </Link>
          ),
          key: "my-profile",
        },

        {
          label: <a href={`${ROUTES_PATH.LOGOUT}`}>{translate("common.titleLogout")}</a>,
          key: "logout",
        },
      ],
    },
  ];

  function logoClickHandler() {
    removeLocalStorageData(STORELIST);
    removeLocalStorageData(ORDERTYPE);
    removeLocalStorageData(TABLECOLUMN);
    removeLocalStorageData(FILTERVALUE);
    removeLocalStorageData(SAVEDVIEWFILTER);
    navigate(`${ROUTES_PATH.ORDERS}`);
  }
  return (
    <Layout className="main-header">
      <Row>
        <Col span={12}>
          <Header className="layout-header-style">
            <Button
              type="text"
              icon={collapsed ? <MenuOutlined /> : <MenuOutlined />}
              onClick={() => setCollapsed(!collapsed)}
            />
            <Button
              style={{ border: "none", background: "transparent" }}
              onClick={logoClickHandler}
            >
              <Logo
                imageWidth={"100px"}
                imageHeight={"auto"}
                isPreview={false}
                className={"headerLogo"}
                imageUrl={"../../../logo-massive.png"}
              />
            </Button>
          </Header>
        </Col>
        <Col span={12} className="right-navigation">
          <Menu
            triggerSubMenuAction="click"
            theme="light"
            mode="horizontal"
            items={items}
            className="justify-right"
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default HeaderComponent;
