import { IColumnProps } from "interfaces/ClientInterface/IEditOrder";
import { IFilterItemProps } from "interfaces/ClientInterface/IOrderList";

export const setLocalStorageData = (str:string, value : string) =>{
  return localStorage.setItem(str, value);
};

export const removeLocalStorageData = (str:string)=>{
  return localStorage.removeItem(str);
};

export const getLocalStorageData = (str:string) =>{
  return localStorage.getItem(str);
};

export const setLocalStorageDataForFilters = (str:string, value:IFilterItemProps[]) =>{
  return localStorage.setItem(str, JSON.stringify(value));
};

export const setLocalStorageDataForColumns = (str:string, value:IColumnProps[]) =>{
  return localStorage.setItem(str, JSON.stringify(value));
};