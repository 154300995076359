import React from "react";
import { Col, Row, theme } from "antd";
import "./HeaderRibbon.scss";

const { useToken } = theme;

interface ribbonProps {
  ribbonHeight: number;
}

export default function HeaderRibbon(props: ribbonProps) {
  const { ribbonHeight } = props;
  const { token } = useToken();
  return (
    <Row>
      <Col
        span={24}
        style={{ height: ribbonHeight, backgroundColor: token.colorPrimary }}
        className="ribbon-container"
      ></Col>
    </Row>
  );
}
