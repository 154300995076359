import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrder } from "interfaces/ClientInterface/IEditOrder";
import { IOpenPriceNote } from "interfaces/ClientInterface/IOrderList";

interface IEditOrderState {
  accountId: string;
  priceNoteChange: IOpenPriceNote;
  orderDetails: IOrder;
}

const initialState: IEditOrderState = {
  accountId: "",
  priceNoteChange: {
    visible: false,
    OrderProductId: "",
    index: -1,
    notes: "",
  },
  orderDetails: {
    OrderId: "",
    ClassId: 0,
    ClassNumber: "",
    OmsOrderStateId: 0,
    OmsPaymentStateId: 0,
    OmsCookieMappingId: 0,
    UserId: 0,
    BillingAddressId: "",
    AccountId: 0,
    PortalId: 0,
    OrderType: "",
    OrderOrigin: "",
    ProductionDate: "",
    OrderDate: "",
    OrderStateName: "",
    OrderPaymentState: "",
    UserName: "",
    StoreName: "",
    OrderTotal: "",
    AccountName: "",
    FullName: "",
    CreatedByFullName: "",
    ModifiedByFullName: "",
    AssignToFullName: "",
    AssignTo: 0,
    FinalizedDate: "",
    OrderTypeName: "",
    LocaleId: 0,
    OrderPermission: "",
    CreatedBy: 0,
    CreatedDate: "",
    ModifiedBy: 0,
    ModifiedDate: "",
    ShipDate: "",
    OrderDetail: "",
    AssignToName: "",
    MappingId: "",
    TypeOfMapping: "",
   
  },
};

export const editOrderSlice = createSlice({
  name: "editOrder",
  initialState,
  reducers: {
    setPriceOrderNotesDetails: (state, action: PayloadAction<IOpenPriceNote>) => {
      const data = { ...state.priceNoteChange, ...action.payload };
      state.priceNoteChange = data;
    },
    setOrderDetails: (state, action: PayloadAction<IOrder>) => {
      state.orderDetails = action.payload;
    },
    getAccountId: (state, action: PayloadAction<string>) => {
      state.accountId = action.payload;
    },
  },
});

export const { setPriceOrderNotesDetails, setOrderDetails, getAccountId } = editOrderSlice.actions;
export default editOrderSlice.reducer;
