import { createIntl, createIntlCache } from "react-intl";
import combinedEnglishJSON from "./Translation/English";
import { messagesProps } from "interfaces/ClientInterface/ILocalizationI18n";

export const messages: messagesProps = {
  en: combinedEnglishJSON,
};

const cache = createIntlCache();

let int = createIntl(
  {
    locale: "en",
    messages: messages["en"],
  },
  cache
);

export function changeLanguage(lang: string) {
  const newIntl = createIntl(
    {
      locale: lang,
      messages: messages[lang],
    },
    cache
  );
  int = newIntl;
}

// eslint-disable-next-line
export const translate = (id: string, values?: {}) => {
  return int.formatMessage({ id }, values);
};
