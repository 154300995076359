import { httpApi } from "api/http.api";
import { LOGIN_COOKIE, REMEMBER_COOKIE } from "config/constant";
import { IUser } from "interfaces/ClientInterface/IUser";
import Cookies from "js-cookie";
import { parseJwt } from "utils";

/**
 * Dummy user
 */
export const testUser = {
  id: 1,
  firstName: "Christopher",
  lastName: "Johnson",
  imgUrl: "test.png",
  userName: "@john1989",
  email: {
    name: "christopher.johnson@altence.com",
    verified: true,
  },
  phone: {
    number: "+18143519459",
    verified: true,
  },
  sex: "male",
  birthday: "01/26/2022",
  lang: "en",
  country: "GB",
  city: "London",
  address1: "14 London Road",
  zipcode: 5211,
  website: "altence.com",
  socials: {
    twitter: "@altence_team",
    facebook: "https://facebook.com/groups/1076577369582221",
    linkedin: "https://linkedin.com/company/altence",
  },
};

export const persistToken = (token: string, refresh_token: string): void => {
  localStorage.setItem("accessToken", token);
  localStorage.setItem("refresh_token", refresh_token);
  localStorage.setItem("account_info", JSON.stringify(parseJwt(token)));
};

export const readToken = (): string => {
  return localStorage.getItem("accessToken") || "";
};

export const persistUser = (user: IUser): void => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const readUser = (): IUser | null => {
  const userStr = localStorage.getItem("user");

  return userStr ? JSON.parse(userStr) : testUser;
};

export const deleteToken = (): void => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("account_info");
};

export const getAccountInfo = (): { ClientID: string; AccountID: string } => {
  const accountInfo = localStorage.getItem("account_info");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return accountInfo ? (JSON.parse(accountInfo) as any) : null;
};

export const getClient = (): string | null => {
  const accountInfo = localStorage.getItem("account_info");
  if (accountInfo) {
    const parsedAccountDetails = JSON.parse(accountInfo);
    return parsedAccountDetails.ClientID ? parsedAccountDetails.ClientID : null;
  }
  return null;
};

export const isAccount = (): boolean => {
  const accountInfo = localStorage.getItem("account_info");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parsedAccountInfo = accountInfo ? (JSON.parse(accountInfo) as any) : null;

  return parsedAccountInfo ? (parsedAccountInfo.AccountID ? true : false) : false;
};

export const deleteUser = (): void => localStorage.removeItem("user");

export const removeAllCookies = (): void => {
  Cookies.remove(LOGIN_COOKIE);
  Cookies.remove(REMEMBER_COOKIE);
  localStorage.clear();
};

/**
 * This function reads the "Znode-UserId" value from localStorage and
 * returns it as a string, or an
 * empty string if it doesn't exist.
 */
export const readUserId = (): string => {
  return localStorage.getItem("Znode-UserId") || "";
};

/**
 * This function stores a user ID in local storage.
 * @param {string} userId - The `userId` parameter is a string
 * that represents the unique identifier of
 * a user (Znode-UserId). This function takes this `userId` and
 * stores it in the browser's `localStorage` as a JSON
 * string with the key "Znode-UserId".
 */
export const persistUserId = (userId: string): void => {
  localStorage.setItem("Znode-UserId", userId);
};

/**
 * This function sets a header in Axios requests with a user ID.
 * @param {string} userId - The `userId` parameter is a string that
 * represents the unique identifier of
 * a user (Znode-UserId). This function sets a custom header called "Znode-UserId" in the
 * Axios HTTP client with the provided `userId` value.
 */
export const setAxiosHeader = (userId: string): void => {
  httpApi.defaults.headers.common["Znode-UserId"] = userId;
};

/**
 * The `persistUserDetails` function takes user details as input and stores them in the local
 * storage.
 * @param data - The `data` parameter is an object that contains the user details. It has three
 * properties: UserName, FirstName and LastName
 */
export const persistUserDetails = (data: {
  UserName: string;
  FirstName: string;
  LastName: string;
}): void => {
  localStorage.setItem("user-details", JSON.stringify(data));
};

/**
 * The function `getUserDetails` retrieves and parses the "user-details"
 * item from the localStorage
 * and returns it as an object.
 */
export const getUserDetails = () => {
  return localStorage.getItem("user-details");
};

/**
 * This function removes all the keys or data from the localStorage.
 */
export const clearLocalStorage = (): void => {
  localStorage.clear();
};

/**
 * The `persistGlobalSetting` function takes global setting as input and store them in the local
 * storage.
 * @param data - The `data` parameter is an object that contains the global setting. It has three
 * properties: TimeZone, DateFormat and TimeFormat
 */
export const persistGlobalSetting = (data: {
  DisplayTimeZone: string;
  ServerTimeZone: string;
  DateFormat: string;
  TimeFormat: string;
  PriceRoundOff: string;
}) => {
  localStorage.setItem("global-settings", JSON.stringify(data));
};

/**
 * The function `getGlobalSetting` retrieves and parses the "global-settings"
 * item from the local Storage
 * and returns it as an object.
 */
export const getGlobalSetting = () => {
  return localStorage.getItem("global-settings");
};