/* eslint-disable max-len */
import React, { useState } from "react";
import { Button, Input, Space, Popover, Typography } from "antd";
import { translate } from "i18n";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { setPriceOrderNotesDetails } from "store/slices/editOrder.slice";

const { TextArea } = Input;
const { Text } = Typography;

interface IPriceNoteChange {
  OrderProductId: string;
  // eslint-disable-next-line no-unused-vars
  handlePriceChangeNote: (action: string) => void;
  getOrderDetails: () => void;
}

export const PriceNoteChange: React.FC<IPriceNoteChange> = ({
  OrderProductId,
  handlePriceChangeNote,
  getOrderDetails,
}: IPriceNoteChange) => {
  const [notes, setNotes] = useState("");
  const [noteValidation, setNoteValidation] = useState("");
  const dispatch = useAppDispatch();
  const priceNoteChangeInfo = useAppSelector((state) => state.editOrder.priceNoteChange);
  const onCancelHandler = () => {
    handlePriceChangeNote && handlePriceChangeNote("cancel");
    clearData();
  };

  const addNoteHandler = () => {
    if (notes?.trim() == "") {
      setNoteValidation(translate("editOrder.RequireOrderNote"));
      return;
    }
    handlePriceChangeNote && handlePriceChangeNote("add");
    clearData();
    getOrderDetails();
  };

  const clearData = () => {
    setNotes("");
    setNoteValidation("");
    dispatch(
      setPriceOrderNotesDetails({
        OrderProductId: "",
        visible: false,
        index: -1,
        notes: "",
      })
    );
  };

  return (
    <Popover
      title=""
      content={
        <Space className="m-0" size={8} direction="vertical">
          <Text className="txt-bold">{translate("editOrder.priceChangeReason")}</Text>
          <TextArea
            value={notes}
            onChange={(e) => {
              setNoteValidation("");
              setNotes(e.target.value);
              dispatch(
                setPriceOrderNotesDetails({
                  ...priceNoteChangeInfo,
                  notes: e.target.value,
                })
              );
            }}
            placeholder={translate("editOrder.addNotesPlaceholder")}
            rows={3}
            data-test-selector="txtNotes"
            style={{ width: 280 }}
          />
          {noteValidation && <div className="error-text">{noteValidation}</div>}
          <Space>
            <Button
              size="middle"
              type="primary"
              data-test-selector="btnAddNote"
              onClick={addNoteHandler}
            >
              {translate("editOrder.addNoteBtn")}
            </Button>
            <Button size="middle" onClick={onCancelHandler}>
              {translate("editOrder.cancelBtn")}
            </Button>
          </Space>
        </Space>
      }
      trigger="click"
      open={priceNoteChangeInfo?.visible && priceNoteChangeInfo.OrderProductId == OrderProductId}
      placement="topRight"
    />
  );
};
