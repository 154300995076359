import React, { Dispatch, SetStateAction } from "react";
import { Space, Tag, Tooltip } from "antd";
import type { MenuProps } from "antd";
import {
  EditOutlined,
  EyeOutlined,
  CopyOutlined,
  FileProtectOutlined,
  // AuditOutlined,
} from "@ant-design/icons";
import type { TabsProps } from "antd";
import Alerts from "components/Common/Alerts/Alerts";
import ServiceRequest from "components/Common/ServiceRequest/ServiceRequest";
import { Link } from "react-router-dom";
import { themeSetting } from "config/defaultSettings";
import { IQuickViewOpen } from "interfaces/ClientInterface/IQuickViewOrder";
import QuickView from "components/Common/QuickViewOrder/QuickViewOrder";
import { IOrderDetails } from "interfaces/APIInterface/IQuickViewOrder";
import { IDuplicateOrder } from "interfaces/ClientInterface/IOrderList";
import { ROUTES_PATH } from "routes/Routes.Constant";
import { translate } from "i18n";
import { IData } from "interfaces/ClientInterface/IList";
import { convertDateTime } from "utils";

export const tabItems: TabsProps["items"] = [
  {
    key: "1",
    label: translate("dashboard.product"),
    children: <ServiceRequest />,
  },
  {
    key: "2",
    label: translate("dashboard.shipping"),
    children: <ServiceRequest />,
  },
  {
    key: "3",
    label: translate("dashboard.payment"),
    children: <Alerts />,
  },
];

export const columnsList = [
  {
    title: translate("dashboard.orderNumber"),
    dataIndex: "ClassNumber",
    key: "ClassNumber",
    sorter: (a: { ClassNumber: string }, b: { ClassNumber: string }) => {
      const orderCode = a?.ClassNumber || "";
      const orderName = b?.ClassNumber || "";
      return orderCode.localeCompare(orderName);
    },
    render: (ClassNumber: string, record: IData) => (
      <Link
        to={`/${ROUTES_PATH.EDIT_ORDER}/${record?.OrderId}`}
        target="_self"
        className="link-inherit"
      >
        {ClassNumber}
      </Link>
    ),
  },
  {
    title: translate("dashboard.account"),
    dataIndex: "AccountName",
    key: "AccountName",
    sorter: (a: { AccountName: string }, b: { AccountName: string }) => {
      const accountCode = a?.AccountName || "";
      const accountName = b?.AccountName || "";
      return accountCode.localeCompare(accountName);
    },
  },
  {
    title: translate("dashboard.customer"),
    dataIndex: "UserName",
    key: "UserName",
    sorter: (a: { UserName: string }, b: { UserName: string }) => {
      const customerCode = a?.UserName || "";
      const customerName = b?.UserName || "";
      return customerCode.localeCompare(customerName);
    },
  },

  {
    title: translate("dashboard.status"),
    dataIndex: "OrderStateName",
    key: "OrderStateName",
    sorter: (a: { OrderStateName: string }, b: { OrderStateName: string }) => {
      const statusCode = a?.OrderStateName || "";
      const statusName = b?.OrderStateName || "";
      return statusCode.localeCompare(statusName);
    },
    render: (status: string) => (
      <Tag color={status === "OPEN" ? "geekblue" : "green"} key={status}>
        {status?.toUpperCase()}
      </Tag>
    ),
  },
  {
    title: translate("dashboard.created"),
    dataIndex: "CreatedDate",
    key: "CreatedDate",
    sorter: (a: { CreatedDate: string }, b: { CreatedDate: string }) => {
      const createdCode = a?.CreatedDate || "";
      const createdName = b?.CreatedDate || "";
      return createdCode.localeCompare(createdName);
    },
    render: (createdDate: string) => <span>{convertDateTime(createdDate)}</span>,
  },
  {
    title: translate("dashboard.time"),
    dataIndex: "CreatedDate",
    key: "CreatedDate",
    sorter: (a: { CreatedDate: string }, b: { CreatedDate: string }) => {
      const lastCode = a?.CreatedDate || "";
      const lastName = b?.CreatedDate || "";
      return lastCode.localeCompare(lastName);
    },
    render: (createdDate: string) => <span>{convertDateTime(createdDate)}</span>,
  },
  {
    title: translate("dashboard.total"),
    dataIndex: "OrderTotal",
    key: "OrderTotal",
    sorter: (a: { OrderTotal: string }, b: { OrderTotal: string }) => {
      const x = a?.OrderTotal || "";
      const y = b?.OrderTotal || "";
      return x.localeCompare(y);
    },
  },
];

export const columns = (
  // eslint-disable-next-line no-unused-vars
  onEditItemPress: (data: string) => void,
  quickViewProps: {
    quickViewOpen: IQuickViewOpen;
    setQuickViewOpen: Dispatch<SetStateAction<IQuickViewOpen>>;
    quickViewData: IOrderDetails;
    // eslint-disable-next-line no-unused-vars
    onQuickView: (id: string) => void;
    quickViewLoading: boolean;
  },
  setIsDuplicateOrder: Dispatch<SetStateAction<IDuplicateOrder>>
) => {
  const { quickViewOpen, setQuickViewOpen, quickViewData, onQuickView, quickViewLoading } =
    quickViewProps;
  return [
    ...columnsList,
    {
      key: "action",
      title: translate("dashboard.actions"),
      dataIndex: "Actions",
      sorter: false,
      hidden: false,
      render: (text: string, record: IData) => {
        return (
          <>
            <Space size="middle">
              <Tooltip title={translate("dashboard.tooltipEdit")}>
                <EditOutlined
                  onClick={() => onEditItemPress(record?.OrderId)}
                  style={{ color: themeSetting.token.colorIcon }}
                />
              </Tooltip>
              <QuickView
                data={quickViewData}
                open={quickViewOpen?.visible && record?.OrderId == quickViewOpen?.id}
                setOpen={(newOpen: boolean) => {
                  setQuickViewOpen({
                    id: record?.OrderId,
                    visible: newOpen,
                  });
                }}
                loading={quickViewLoading}
              >
                <div
                  className={
                    quickViewOpen?.visible && record?.OrderId == quickViewOpen?.id
                      ? "eye-icon-style eye-icon-active"
                      : "eye-icon-style eye-icon-inactive"
                  }
                >
                  <Tooltip title={translate("dashboard.QuickView")}>
                    <EyeOutlined
                      onClick={() => {
                        onQuickView(record?.OrderId);
                      }}
                    />
                  </Tooltip>
                </div>
              </QuickView>
              <Tooltip title={translate("dashboard.tooltipDuplicate")}>
                <CopyOutlined
                  onClick={() => {
                    setIsDuplicateOrder({
                      orderId: record?.OrderId,
                      visible: true,
                    });
                  }}
                  style={{ color: themeSetting.token.colorIcon }}
                />
              </Tooltip>
            </Space>
          </>
        );
      },
    },
  ];
};

export const items: MenuProps["items"] = [
  {
    key: "orders",
    icon: <FileProtectOutlined />,
    label: (
      <Link to="/orders" target="_self" title={translate("dashboard.menuItemOrder")}>
        {translate("dashboard.menuItemOrder")}
      </Link>
    ),
  },
  // {
  //   key: "reports",
  //   icon: <AuditOutlined />,
  //   label: (
  //     <Link to="/" target="_self" title={translate("dashboard.menuItemReports")}>
  //       {translate("dashboard.menuItemReports")}
  //     </Link>
  //   ),
  // },
];
