import { DOLLAR } from "config/constant";
import dayjs from "dayjs";
import React from "react";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { getGlobalSetting } from "api/services/localStorage.service";
import { DateFormatMapper, TimeFormatMapper, TimezoneMapper } from "config/timeZone";
const globalsettings = getGlobalSetting() ? JSON.parse(getGlobalSetting() || "") : {};

dayjs.extend(utc);
dayjs.extend(timezone);

export const camelize = (string: string): string => {
  return string
    .split(" ")
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)
    )
    .join("");
};

export const getDate = (date: string): string => {
  if (!date) return "";
  return date.split("T")[0];
};

export const getCurrencyPrice = (price: number, currency: string): string => {
  switch (currency) {
    case "USD": {
      return `$${price}`;
    }

    default: {
      return `$${price}`;
    }
  }
};

export const parseJwt = (token: string) => {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const trimStr = (str: string, charCount = 15) => {
  if (!str) return "";
  const res = str.substring(0, charCount);
  return res + "...";
};

export const isMorePages = (dataLength: number, perPageRecord = 10) => {
  dataLength = dataLength ? dataLength : 0;
  return dataLength > perPageRecord ? true : false;
};

export const getFooterText = () => {
  const today = new Date();
  const year = today.getFullYear();

  return `Powered By Znode. © ${year}, Znode LLC, All Rights Reserved.`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObjectEmpty = (obj: any = {}) => {
  return Object.keys(obj).length === 0;
};

// Generate the date and time string
export const dateString = () => {
  const currentDate = new Date();
  /* eslint-disable-next-line newline-per-chained-call */
  return currentDate?.toISOString()?.replace(/[-:]/g, "")?.slice(0, 14);
};

/**
 * This function is used to get the price prefixed with the passed currency symbol
 */
export const getPriceWithCurrency = (currency: string, price: number | string | null) => {
  if (!currency) {
    currency = DOLLAR;
  }

  return price != null ? `${currency}${price}` : "";
};

export const formatSelector = (data: string) => {
  return data?.trim()?.toLowerCase();
};

export function deepCloneArray<T>(arr: T[]): T[] {
  return arr.map((item) => {
    if (Array.isArray(item)) {
      return deepCloneArray(item); // Recursively clone nested arrays
    } else if (typeof item === "object" && item !== null) {
      return { ...item }; // Clone objects using the spread operator
    }
    return item; // Return primitive values as is
  }) as T[];
}

export const checkPermissionToShow = (permission: boolean, section: React.ReactNode) => {
  return permission ? section : null;
};

/*
 This method returns Date and time according to timezone which is set in global setting for display.
*/
export const convertDateTime = (date: string) => {
  const dateFormat = globalsettings?.DateFormat;
  const timeFormat = globalsettings?.TimeFormat;
  const displayTimeZone = globalsettings?.DisplayTimeZone;

  const convertedDate = dayjs
    .utc(date)
    .tz(TimezoneMapper[displayTimeZone])
    .format(DateFormatMapper[dateFormat] + " " + TimeFormatMapper[timeFormat]);
  return convertedDate;
};

/**
 * This method returns date according to the timezone which is set in global setting for display.
 *
 * @param date
 * @returns
 */
export const convertDate = (date: string) => {
  const dateFormat = globalsettings?.DateFormat;
  const targetTimeZone = globalsettings?.DisplayTimeZone;

  const convertedDate = dayjs
    .utc(date)
    .tz(TimezoneMapper[targetTimeZone])
    .format(DateFormatMapper[dateFormat]);
  return convertedDate;
};

/**
 * This method returns time according to the timezone which is set in global setting for display.
 * @param date
 * @returns
 */
export const convertTime = (date: string) => {
  const timeFormat = globalsettings?.TimeFormat;
  const targetTimeZone = globalsettings?.DisplayTimeZone;

  const convertedDate = dayjs
    .utc(date)
    .tz(TimezoneMapper[targetTimeZone])
    .format(TimeFormatMapper[timeFormat]);
  return convertedDate;
};