import React from "react";
import { Tag as AntdTag } from "antd";
import { IStatusTag } from "interfaces/ClientInterface/IStatus";

export const StatusTag: React.FC<IStatusTag> = ({
  text,
  icon,
  closable,
  onCloseClick,
  dataTestSelector,
  closeIcon,
  id,
  tagStyle,
}: IStatusTag) => {
  const onCancelPress = () => {
    onCloseClick && onCloseClick(id as string);
  };
  return (
    <div>
      <AntdTag
        icon={icon}
        color={tagStyle.backgroundColor}
        closable={closable}
        onClose={onCancelPress}
        data-test-selector={dataTestSelector}
        closeIcon={closeIcon}
        style={{
          border: tagStyle.border,
        }}
      >
        <span
          style={{
            color: tagStyle.color,
            fontSize: "12px",
          }}
        >
          {text}
        </span>
      </AntdTag>
    </div>
  );
};

export default StatusTag;
