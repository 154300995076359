import React from "react";
import { Card, Row, Col } from "antd";
import "../../Dashboard/Dashboard.scss";
import { translate } from "i18n";

export default function ServiceRequest() {
  return (
    <Card
      title="Service Requests"
      extra={
        <a href="#" data-test-selector="viewAllRequest">
          {translate("common.linkViewAll")}
        </a>
      }
    >
      <Row gutter={16}>
        <Col className="gutter-row" span={24}>
          <div className="list-elements">
            <Row gutter={16}>
              <Col className="gutter-row" span={17}>
                <div className="date-label">
                  <label>{translate("common.labelContactFormRequest")}</label>
                </div>
                <div className="alerts-elements">
                  <a href="#" data-test-selectors="alertsElements">
                    Carrie Burda
                  </a>
                </div>
              </Col>
              <Col className="gutter-row" span={7}>
                <div className="date-label">
                  <label>May 14, 2023</label>
                </div>
              </Col>
            </Row>
          </div>
          <div className="list-elements">
            <Row gutter={16}>
              <Col className="gutter-row" span={17}>
                <div className="date-label">
                  <label>{translate("common.labelContactFormRequest")}</label>
                </div>
                <div className="alerts-elements">
                  <a href="#" data-test-selectors="alertsElements">
                    Marion Means
                  </a>
                </div>
              </Col>
              <Col className="gutter-row" span={7}>
                <div className="date-label">
                  <label>May 14, 2023</label>
                </div>
              </Col>
            </Row>
          </div>
          <div className="list-elements">
            <Row gutter={16}>
              <Col className="gutter-row" span={17}>
                <div className="date-label">
                  <label>{translate("common.labelContactFormRequest")}</label>
                </div>
                <div className="alerts-elements">
                  <a href="#" data-test-selectors="alertsElements">
                    Brian Santori
                  </a>
                </div>
              </Col>
              <Col className="gutter-row" span={7}>
                <div className="date-label">
                  <label>May 14, 2023</label>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
