type TimezoneMapperType = {
  [key: string]: string;
};

//To map the time zones into IANA time zone identifier which is recognized by day.js
export const TimezoneMapper: TimezoneMapperType = {
  "(UTC-12:00) International Date Line West": "Etc/GMT+12",
  "(UTC-11:00) Midway Island, Samoa": "Pacific/Apia",
  "(UTC-10:00) Hawaii": "Pacific/Honolulu",
  "(UTC-09:00) Alaska": "America/Anchorage",
  "(UTC-08:00) Pacific Time (US & Canada)": "America/Los_Angeles",
  "(UTC-08:00) Tijuana, Baja California": "America/Tijuana",
  "(UTC-07:00) Arizona": "America/Phoenix",
  "(UTC-07:00) Chihuahua, La Paz, Mazatlan - New": "America/Chihuahua",
  "(UTC-07:00) Chihuahua, La Paz, Mazatlan - Old": "America/Mazatlan",
  "(UTC-07:00) Mountain Time (US & Canada)": "America/Denver",
  "(UTC-06:00) Central America": "America/Guatemala",
  "(UTC-06:00) Central Time (US & Canada)": "America/Chicago",
  "(UTC-06:00) Guadalajara, Mexico City, Monterrey - New": "America/Mexico_City",
  "(UTC-06:00) Guadalajara, Mexico City, Monterrey - Old": "America/Monterrey",
  "(UTC-06:00) Saskatchewan": "America/Regina",
  "(UTC-05:00) Bogota, Lima, Quito": "America/Bogota",
  "(UTC-05:00) Eastern Time (US & Canada)": "America/New_York",
  "(UTC-05:00) Indiana (East)": "America/Indiana/Indianapolis",
  "(UTC-04:30) Caracas": "America/Caracas",
  "(UTC-04:00) Asuncion": "America/Asuncion",
  "(UTC-04:00) Atlantic Time (Canada)": "America/Halifax",
  "(UTC-04:00) Georgetown, La Paz, San Juan": "America/Puerto_Rico",
  "(UTC-04:00) Manaus": "America/Manaus",
  "(UTC-04:00) Santiago": "America/Santiago",
  "(UTC-03:30) Newfoundland": "America/St_Johns",
  "(UTC-03:00) Brasilia": "America/Sao_Paulo",
  "(UTC-03:00) Buenos Aires": "America/Argentina/Buenos_Aires",
  "(UTC-03:00) Cayenne": "America/Cayenne",
  "(UTC-03:00) Greenland": "America/Godthab",
  "(UTC-03:00) Montevideo": "America/Montevideo",
  "(UTC-02:00) Mid-Atlantic": "Atlantic/South_Georgia",
  "(UTC-01:00) Azores": "Atlantic/Azores",
  "(UTC-01:00) Cape Verde Is.": "Atlantic/Cape_Verde",
  "(UTC) Casablanca": "Africa/Casablanca",
  "(UTC) Coordinated Universal Time": "Etc/UTC",
  "(UTC) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London": "Europe/London",
  "(UTC) Monrovia, Reykjavik": "Africa/Monrovia",
  "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna": "Europe/Amsterdam",
  "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague": "Europe/Belgrade",
  "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris": "Europe/Brussels",
  "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb": "Europe/Sarajevo",
  "(UTC+01:00) West Central Africa": "Africa/Lagos",
  "(UTC+02:00) Amman": "Asia/Amman",
  "(UTC+02:00) Athens, Bucharest, Istanbul": "Europe/Athens",
  "(UTC+02:00) Beirut": "Asia/Beirut",
  "(UTC+02:00) Cairo": "Africa/Cairo",
  "(UTC+02:00) Harare, Pretoria": "Africa/Johannesburg",
  "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius": "Europe/Helsinki",
  "(UTC+02:00) Jerusalem": "Asia/Jerusalem",
  "(UTC+02:00) Minsk": "Europe/Minsk",
  "(UTC+02:00) Windhoek": "Africa/Windhoek",
  "(UTC+03:00) Baghdad": "Asia/Baghdad",
  "(UTC+03:00) Kuwait, Riyadh": "Asia/Riyadh",
  "(UTC+03:00) Moscow, St. Petersburg, Volgograd": "Europe/Moscow",
  "(UTC+03:00) Nairobi": "Africa/Nairobi",
  "(UTC+03:30) Tehran": "Asia/Tehran",
  "(UTC+04:00) Abu Dhabi, Muscat": "Asia/Dubai",
  "(UTC+04:00) Baku": "Asia/Baku",
  "(UTC+04:00) Caucasus Standard Time": "Asia/Yerevan",
  "(UTC+04:00) Port Louis": "Indian/Mauritius",
  "(UTC+04:00) Tbilisi": "Asia/Tbilisi",
  "(UTC+04:00) Yerevan": "Asia/Yerevan",
  "(UTC+04:30) Kabul": "Asia/Kabul",
  "(UTC+05:00) Ekaterinburg": "Asia/Yekaterinburg",
  "(UTC+05:00) Islamabad, Karachi": "Asia/Karachi",
  "(UTC+05:00) Tashkent": "Asia/Tashkent",
  "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi": "Asia/Kolkata",
  "(UTC+05:30) Sri Jayawardenepura": "Asia/Colombo",
  "(UTC+05:45) Kathmandu": "Asia/Kathmandu",
  "(UTC+06:00) Astana, Dhaka": "Asia/Almaty",
  "(UTC+06:00) Novosibirsk": "Asia/Novosibirsk",
  "(UTC+06:30) Yangon (Rangoon)": "Asia/Yangon",
  "(UTC+07:00) Bangkok, Hanoi, Jakarta": "Asia/Bangkok",
  "(UTC+07:00) Krasnoyarsk": "Asia/Krasnoyarsk",
  "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi": "Asia/Shanghai",
  "(UTC+08:00) Irkutsk": "Asia/Irkutsk",
  "(UTC+08:00) Kuala Lumpur, Singapore": "Asia/Kuala_Lumpur",
  "(UTC+08:00) Perth": "Australia/Perth",
  "(UTC+08:00) Taipei": "Asia/Taipei",
  "(UTC+08:00) Ulaanbaatar": "Asia/Ulaanbaatar",
  "(UTC+09:00) Osaka, Sapporo, Tokyo": "Asia/Tokyo",
  "(UTC+09:00) Seoul": "Asia/Seoul",
  "(UTC+09:00) Yakutsk": "Asia/Yakutsk",
  "(UTC+09:30) Adelaide": "Australia/Adelaide",
  "(UTC+09:30) Darwin": "Australia/Darwin",
  "(UTC+10:00) Brisbane": "Australia/Brisbane",
  "(UTC+10:00) Canberra, Melbourne, Sydney": "Australia/Sydney",
  "(UTC+10:00) Guam, Port Moresby": "Pacific/Guam",
  "(UTC+10:00) Hobart": "Australia/Hobart",
  "(UTC+10:00) Vladivostok": "Asia/Vladivostok",
  "(UTC+11:00) Magadan, Solomon Is., New Caledonia": "Asia/Magadan",
  "(UTC+12:00) Auckland, Wellington": "Pacific/Auckland",
  "(UTC+12:00) Fiji, Marshall Is.": "Pacific/Fiji",
  "(UTC+12:00) Petropavlovsk-Kamchatsky": "Asia/Kamchatka",
  "(UTC+13:00) Naku'alofa": "Pacific/Tongatapu"
};

type DateFormatMapperType = {
  [key: string]: string;
};

//To map the date formats into the formats that are supported by day.js.
export const DateFormatMapper: DateFormatMapperType = {
  "MM/dd/yyyy": "MM/DD/YYYY",
  "dd-MMM-yyyy": "DD-MMM-YYYY",
  "MM/dd/yy": "MM/DD/YY",
  "yyyy-MM-dd": "YYYY-MM-DD",
  "dd-MMM-yy": "DD-MMM-YY",
}; 

type TimeFormatMapperType = {
  [key: string]: string;
};

//To map the time formats into the formats that are supported by day.js.
export const TimeFormatMapper: TimeFormatMapperType = {
  "hh:mm:ss tt": "hh:mm:ss A",
  "hh:mm tt": "hh:mm A",
  "HH:mm:ss": "HH:mm:ss",
  "HH:mm": "HH:mm",
}; 