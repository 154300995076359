import authReducer from "store/slices/auth.slice";
import useReducer from "store/slices/user.slice";
import permissionReducer from "store/slices/permission.slice";
import editOrderReducer from "store/slices/editOrder.slice";

const reducer = {
  auth: authReducer,
  user: useReducer,
  permission: permissionReducer,
  editOrder: editOrderReducer
};

export default reducer;
