import { IAbilityPermission, ISecurityPermission } from "interfaces/ClientInterface/IAbility";

export const mapPermissionsToAbility = (permissions: ISecurityPermission[] = []) => {
  // Dynamic role permissions
  const permissionList: IAbilityPermission[] = Array?.from(
    permissions?.reduce(
      (m: Map<string, string[]>, { Object, PermissionCode }) =>
        m.set(Object, [...(m.get(Object) || []), PermissionCode]),
      new Map()
    ),
    ([subject, action]) => ({ subject: subject.replace(/\s/g, ""), action })
  );

  return permissionList;
};

export const PERMISSION_SUBJECT = {
  DASHBOARD: "Dashboard",
  ORDERS: "Orders",
  USER_PROFILE: "UserProfile",
  SECURITY: "Security",
};

export const PERMISSION_CODE = {
  UNASSIGN_ORDERS: "UnassignOrders",
  CREATE_ORDERS: "CreateOrders",
  CHANGE_PRICES: "ChangePrices",
  CHANGE_ORDER_LINE_STATUS: "ChangeOrderLineStatus",
  SET_ANY_ORDER_STATUS: "SetAnyOrderStatus",
  CANCEL_ORDERS: "CancelOrders",
  CHANGE_CSR_DISCOUNT: "ChangeCSRDiscount",
  SELL_ALL_ORDERS: "SeeAllOrders",
  SEE_MY_USER_GROUPS_ORDERS: "SeeMyUserGroupsOrders",
  SEE_ALL_UNASSIGNED_ORDERS: "SeeAllUnassignedOrders",
  SEE_NEXT_UNASSIGNED_ORDERS: "SeeNextUnassignedOrder",
  ASSIGN_TO_ME: "AssignToMe",
  ASSIGN_TO_ANYONE: "AssignToAnyone",
  CAN_BE_ASSIGNED_TO_ORDERS: "CanBeAssignedtoOrders",
  ASSIGN_TO_MY_TEAM: "AssignToMyTeam",
  CREATE_PUBLIC_VIEWS: "CreatePublicViews",
  CREATE_USER_GROUP_VIEWS: "CreateUserGroupViews",
  SHARE_VIEWS_WITH_EVERYONE: "ShareViewswithEveryone",
  SHARE_VIEWS_WITH_USER_GROUPS: "ShareViewswithUserGroups",
  CAN_DELEGATE: "CanDelegate",
  ACCESS_ORDER_MANAGEMENT: "AccessOrderManagement",
};